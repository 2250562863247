import axios from 'axios';
import { showLoading, closeLoading, showMessage } from '@/components/widgets/swalUtils';
import {
  fixedLng,
  convertPointsToCoordinates,
  getCoordinates,
} from '@/components/commandBoard/utils/locationUtils';
import { useToast } from 'vue-toastification';

export const state = {
  currentMapTools: null,
  editingMapTool: null,
  editingMapToolData: null,
};

export const mutations = {
  DISABLE_EDITING_TOOL(state) {
    try {
      if (state.editingMapTool?.layer) {
        return state.editingMapTool.layer.editing.disable();
      }
      if (state.editingMapTool?.target) {
        return state.editingMapTool.target.editing.disable();
      }
      return false;
    } catch (err) {
      console.log('No editing enabled', err.message);
      return false;
    }
  },
  SET_CURRENT_MAPTOOLS(state, payload) {
    Object.keys(payload).forEach((index) => {
      if (!state.currentMapTools) {
        state.currentMapTools = [payload[index]];
      } else {
        state.currentMapTools.push(payload[index]);
      }
    });
  },
  CLEAR_CURRENT_MAPTOOLS(state) {
    state.currentMapTools = null;
  },
  RELOAD_LAYER_CURRENT_MAPTOOLS(state, payload) {
    if (payload.length > 0) {
      const { layer_id } = payload[0];
      // Remove current maptools related to the layer
      const mapTools = state.currentMapTools.filter((maptool) => maptool.layer_id !== layer_id);

      // Add reloaded maptools related to the layer
      Object.keys(payload).forEach((index) => {
        mapTools.push(payload[index]);
      });

      // Update state
      state.currentMapTools = mapTools;
    }
  },
  REMOVE_LAYER_MAPTOOLS(state, layer_id) {
    // Remove current maptools related to the layer
    const mapTools = state.currentMapTools.filter((maptool) => maptool.layer_id !== layer_id);

    // Update state
    state.currentMapTools = mapTools;
  },
  SET_EDITING_MAP_TOOLS(state, payload) {
    state.editingMapTool = payload;
  },
  SET_EDITING_MAP_TOOLS_DATA(state, payload) {
    state.editingMapToolData = payload;
  },
  UPDATE_TOOL_RADIUS(state, payload) {
    if (state.editingMapTool?.layer) {
      state.editingMapTool.layer.setRadius(payload);
    } else {
      state.editingMapTool.target.setRadius(payload);
    }
  },
  CLEAR_EDITING(state) {
    state.editingMapTool = null;
    state.editingMapToolData = null;
  },
};
export const getters = {
  getCurrentMapTools(state) {
    return state.currentMapTools;
  },
  getEditingMapTools(state) {
    return state.editingMapTool;
  },
  getEditingMapToolsData(state) {
    return state.editingMapToolData;
  },
};

function requestValidator({ commit }, {
  request, dispatch, user, incident,
} = {}) {
  try {
    const { maptool_id } = request.data;
    setTimeout(() => {
      dispatch('loadMapTools', {
        user,
        incident,
        invalidate: true,
      });
    }, 500);
    commit('SET_EDITING_MAP_TOOLS', null);
    commit('DISABLE_EDITING_TOOL');
    return !!maptool_id;
  } catch (e) {
    console.log('MapTool error: ', e.message);
    throw new Error(e.message);
  }
}

export const actions = {
  updateMapToolsRadius({ commit }, { radius } = {}) {
    commit('UPDATE_TOOL_RADIUS', radius);
  },
  disableEditingTool({ commit }) {
    commit('DISABLE_EDITING_TOOL');
  },
  setEditingMapTools({ commit }, { tool, data } = {}) {
    commit('DISABLE_EDITING_TOOL');
    commit('CLEAR_EDITING');
    commit('SET_EDITING_MAP_TOOLS', tool);
    commit('SET_EDITING_MAP_TOOLS_DATA', data);
  },
  async createMapTool({ state, commit, dispatch }, {
    form, user, incident,
  } = {}) {
    const { client_id } = user;
    const { id } = incident;
    commit('DISABLE_EDITING_TOOL');
    if (form.id) {
      try {
        showLoading('Editing MapTool');
        const layer = state.editingMapTool.target;
        let location;

        if (
          form.type !== 'marker'
          && form.type !== 'landmark'
          && form.type !== 'circle'
        ) {
          location = getCoordinates(layer, form.type);
        } else {
          const { lat, lng } = form;
          location = { lat, lng };
        }

        const updateMapTools = await axios.patch(
          `/api/json/v1/client/${client_id}/maptools/${form.id}`,
          {
            lat: parseFloat(location.lat),
            lng: fixedLng(location.lng),
            top_left_lat: 0,
            top_left_lng: 0,
            top_right_lat: 0,
            top_right_lng: 0,
            bottom_left_lat: 0,
            bottom_left_lng: 0,
            name: form.name,
            notes: form.notes,
            icon: form.icon,
            layer_id: form.layer_id,
            type: form.type,
            radius: form.radius,
            display: true,
            line_type: form.line_type,
            line_thickness: form.line_thickness,
            color: form.color,
            map_id: 'frontend',
            line_distance:
                location.lineDistance
                  ? location.lineDistance * 0.0006213712
                  : 0,
            show_arrows: form.show_arrows,
            line_points:
                location?.coordinates
                  ? convertPointsToCoordinates(location.coordinates)
                  : [],
          },
        );
        await requestValidator({
          commit,
        }, {
          request: updateMapTools,
          dispatch,
          user,
          incident,
        });
        showMessage({
          title: 'MapTool Updated!',
          icon: 'success',
        });
      } catch (error) {
        showMessage({
          title: 'Error saving map tool',
          text: error.message,
          icon: 'error',
        });
      }
    } else {
      try {
        showLoading('Creating MapTool');

        const { layer, layerType } = state.editingMapTool;

        let location;

        if (layerType !== 'marker' && layerType !== 'circlemarker'
        && layerType !== 'circle') {
          location = getCoordinates(layer, layerType);
        } else {
          location = { lat: layer.getLatLng().lat, lng: layer.getLatLng().lng };
        }

        const createMapTool = await axios.post(
          `/api/json/v1/client/${client_id}/incident/${id}/maptools`,
          {

            lat: location.lineDistance ? parseFloat(location.lat) : location.lat,
            lng: fixedLng(location.lng),
            top_left_lat: 0,
            top_left_lng: 0,
            top_right_lat: 0,
            top_right_lng: 0,
            bottom_left_lat: 0,
            bottom_left_lng: 0,
            name: form.name,
            notes: form.notes,
            icon: layerType === 'marker' || layerType === 'landmark'
              ? form.icon
              : '',
            layer_id: form.layer_id,
            type: layerType,
            radius: form.radius,
            display: true,
            line_type: form.line_type,
            line_thickness: form.line_thickness,
            color: form.color,
            map_id: 'frontend',
            line_distance:
              location?.lineDistance
                ? location.lineDistance * 0.0006213712
                : 0,
            show_arrows: form.show_arrows,
            line_points: convertPointsToCoordinates(
              location.coordinates ? location.coordinates : location,
            ),
          },
        );
        await requestValidator({
          commit,
        }, {
          request: createMapTool,
          dispatch,
          user,
          incident,
        });
        showMessage({
          title: 'MapTool Created!',
          icon: 'success',
        });
      } catch (error) {
        console.log(error);
        showMessage({
          title: 'Error saving map tool',
          text: error.message,
          icon: 'error',
        });
      }
    }
    commit('CLEAR_EDITING');
  },
  async filterMapTools({ state }, { layers } = {}) {
    const tools = [];
    if (state.currentMapTools) {
      Object.keys(layers).forEach((index) => {
        Object.keys(state.currentMapTools).forEach((tool) => {
          if (state.currentMapTools[tool].layer_id === layers[index].layer_id) {
            tools.push(state.currentMapTools[tool]);
          }
        });
      });
    }
    return Promise.resolve(tools);
  },
  async deleteMapTools({ commit }, { mapTool, user } = {}) {
    const { client_id } = user;
    // TODO: Create commit to clean only the delete from the currentMapTools
    showLoading('Deleting tool...');
    await axios.delete(`/api/json/v1/client/${client_id}/maptools/${mapTool.id}`).then(async (response) => {
      if (response?.data?.maptool_id) {
        showMessage({
          title: 'Success',
          text: 'MapTool deleted!',
        });
      } else {
        showMessage({
          title: 'Error',
          text: 'Error deleting MapTool',
        });
      }
    }).catch((error) => showMessage({
      title: 'Error',
      text: `We've found a error while deleting the map tool ${error.message}`,
      icon: 'error',
    }));
    return commit('CLEAR_EDITING');
  },
  async loadMapTools({ commit }, {
    user, incident, invalidate, layers = [],
  } = {}) {
    const toast = useToast();
    const toastId = toast('Loading MapTools');

    if (invalidate) {
      commit('CLEAR_CURRENT_MAPTOOLS');
    }

    const { client_id } = user;
    const { id } = incident;
    if (client_id) {
      const tools = await axios.get(
        `/api/json/v1/client/${
          client_id
        }/maptools?incident_id=${
          id}`,
      );
      if (tools) {
        const { maptools } = tools.data;
        commit('SET_CURRENT_MAPTOOLS', maptools);
      }

      Object.keys(layers).forEach(async (index) => {
        if (
          layers[index].layer_id
          && layers[index].is_incident === false
          && layers[index].display === true
        ) {
          const { layer_id } = layers[index];
          // eslint-disable-next-line no-await-in-loop
          const layer_maptools = await axios.get(
            `/api/json/v1/client/${
              client_id
            }/maptools?layer_id=${layer_id}`,
          );
          if (layer_maptools?.data) {
            commit('SET_CURRENT_MAPTOOLS', layer_maptools.data.maptools);
          }
        }
      });

      toast.dismiss(toastId);
      return Promise.resolve(true);
    }
    showMessage({
      title: 'Error',
      text: 'Not able to get the map tools of this incident.',
      icon: 'error',
    });
    return Promise.resolve(false);
  },
  async loadLayerMapTools({ commit }, {
    user, layer,
  } = {}) {
    const { client_id } = user;
    if (client_id) {
      if (
        layer.layer_id
        && layer.is_incident === false
        && layer.display === true
      ) {
        const { layer_id } = layer;
        // eslint-disable-next-line no-await-in-loop
        const layer_maptools = await axios.get(
          `/api/json/v1/client/${
            client_id
          }/maptools?layer_id=${layer_id}`,
        );
        if (layer_maptools?.data) {
          commit('RELOAD_LAYER_CURRENT_MAPTOOLS', layer_maptools.data.maptools);
        }
      }
      closeLoading();
      return Promise.resolve(true);
    }
    showMessage({
      title: 'Error',
      text: 'Not able to get the map tools of this incident.',
      icon: 'error',
    });
    return Promise.resolve(false);
  },
  async removeLayerMapTools({ commit }, {
    layer,
  } = {}) {
    const { layer_id } = layer;

    if (layer_id) {
      commit('REMOVE_LAYER_MAPTOOLS', layer_id);
      return Promise.resolve(true);
    }

    showMessage({
      title: 'Error',
      text: 'Not able to get the map tools of this incident.',
      icon: 'error',
    });
    return Promise.resolve(false);
  },
};
