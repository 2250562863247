import axios from 'axios';
import {
  closeLoading,
  handleErrorResponse,
  handleOkResponse,
  showError,
  showLoading,
} from '@/components/widgets/swalUtils';

export const state = {
  loading: false,
  clientPersonnel: [],
};

export const getters = {
  getLoadingPersonnelStatus(state) {
    return state.loading;
  },
  getClientPersonnel(state) {
    return state.clientPersonnel;
  },
  getAvailablePersonnel(state) {
    return state.clientPersonnel.filter((item) => item.status === 'Available');
  },
  getRespondingPersonnel(state) {
    return state.clientPersonnel.filter((item) => item.status === 'Responding');
  },
  // TODO: check for stagged status
};

export const mutations = {
  SWITCH_LOADING_STATUS(state) {
    state.loading = !state.loading;
  },
  SET_CLIENT_PERSONNEL(state, payload) {
    Object.keys(payload).forEach(
      (index) => state.clientPersonnel.push(payload[index]),
    );
  },
  CLEAR_CLIENT_PERSONNEL(state) {
    state.clientPersonnel = [];
  },
  SET_SELECTED_PERSONNEL(state, payload) {
    const personnel = state.clientPersonnel.filter(
      (item) => item.id === payload?.id,
    );
    if (personnel) {
      // TODO: fix mouse click on input select for switch
      // unit[0].selected = !unit[0].selected;
      personnel[0].selected = true;
    }
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async createPersonnel({ state }, { user, newPersonnelData } = {}) {
    // This will create the personnel in the database (post to the backend API)
    const { client_id } = user;
    showLoading('Adding Personnel...');
    if (client_id) {
      const postUrl = `/api/json/v1/client/${client_id}/personnel`;

      const newPersonnelDataUnits = [];
      newPersonnelData.forEach((personnel) => {
        newPersonnelDataUnits.push(personnel.assign_to_unit);
        delete personnel.assign_to_unit;
        Object.keys(personnel).forEach((key) => {
          if (personnel[key] === '') {
            delete personnel[key];
          }
        });
      });

      const requestsArray = [];
      // Push Assign promises to array
      newPersonnelData.forEach((newPersonnel) => {
        requestsArray.push(axios.post(postUrl, newPersonnel));
      });

      Promise
        .all(requestsArray)
        .then(axios.spread((...responses) => {
          responses.forEach((response, index) => {
            if (response.data.personnel_id && newPersonnelDataUnits[index]) {
              const { personnel_id } = response.data;
              const assign_to_unit = newPersonnelDataUnits[index];

              showLoading('Assigning personnel to Unit...');
              // Attach to Unit
              const assignToUnitUrl = `/api/json/v1/client/${client_id}/personnel/
                ${personnel_id}/assign/unit/${assign_to_unit}`;

              axios.patch(assignToUnitUrl).then((response) => {
                handleOkResponse({
                  response,
                  textSuccess: 'Personnel has been assigned to Unit!',
                });
              });
            }
          });
          closeLoading();
        }))
        .catch((error) => {
          handleErrorResponse(error);
          console.log('Error: ', error);
          console.log('Error Response: ', error.response);
        });
    } else {
      await showError();
    }
  },
  setSelectedPersonnel({ commit }, { personnel } = {}) {
    commit('SET_SELECTED_PERSONNEL', personnel);
  },
  async loadOffsetClientPersonnel({ commit, dispatch, state }, { user, offset } = {}) {
    if (!offset) {
      offset = 50;
    } else {
      offset += 50;
    }

    if (!state.loading) {
      commit('SWITCH_LOADING_STATUS');
    }

    if (state.clientPersonnel.length === offset) {
      const { client_id } = user;
      if (client_id) {
        axios.get(`/api/json/v1/client/${client_id}/personnel?offset=${offset}`)
          .then((response) => {
            if (response.status === 200) {
              if (response?.data?.personnel) {
                commit('SET_CLIENT_PERSONNEL', response.data.personnel);
                dispatch('loadOffsetClientPersonnel', { user, offset });
              }
            } else {
              showError('Unable to load personnel!');
              console.log('Response: ', response);
            }
          })
          .catch((error) => {
            handleErrorResponse(error);
          });
      } else {
        showError('Client not found!');
      }
    } else {
      commit('SWITCH_LOADING_STATUS');
    }
  },
  async loadClientPersonnel({ commit, dispatch, state }, { user, invalidate, loadFull } = {}) {
    if (!state.loading) {
      commit('SWITCH_LOADING_STATUS');
    }

    if (invalidate) {
      commit('CLEAR_CLIENT_PERSONNEL');
    }

    const { client_id } = user;
    if (client_id) {
      axios.get(`/api/json/v1/client/${client_id}/personnel`)
        .then((response) => {
          if (response.status === 200) {
            if (response?.data?.personnel) {
              commit('SET_CLIENT_PERSONNEL', response.data.personnel);
            }
            if (loadFull) {
              dispatch('loadOffsetClientPersonnel', { user });
            } else {
              commit('SWITCH_LOADING_STATUS');
            }
          } else {
            showError('Unable to load personnel!');
            console.log('Response: ', response);
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      showError('Client not found!');
    }
  },
};
