import axios from 'axios';
import {
  showError,
  showLoading,
  closeLoading,
} from '@/components/widgets/swalUtils';
import { useToast } from 'vue-toastification';

export const state = {
  layers: [],
  commonLayers: [],
  displayLayers: [],
  currentLayer: [],
};

export const getters = {
  getCurrentLayer(state) {
    return state.currentLayer;
  },
  getLayers(state) {
    return state.layers;
  },
  getCommonLayers(state) {
    return state.commonLayers;
  },
  getDisplayLayers(state) {
    return state.displayLayers;
  },
};

export const mutations = {
  async SET_CURRENT_LAYER(state, payload) {
    if (state.currentLayer) {
      state.currentLayer.push(payload);
    } else {
      state.currentLayer = [payload];
    }
  },
  SET_LAYERS(state, payload) {
    let layersFirstIndex = 0;
    Object.keys(payload).forEach((key) => {
      state.layers[layersFirstIndex] = payload[key];
      layersFirstIndex += 1;
    });
  },
  SET_COMMON_LAYERS(state, payload) {
    let layersFirstIndex = 0;
    Object.keys(payload).forEach((key) => {
      state.commonLayers[layersFirstIndex] = payload[key];
      layersFirstIndex += 1;
    });
  },
  SET_DISPLAY_LAYERS(state, payload) {
    let layersFirstIndex = 0;
    Object.keys(payload).forEach((key) => {
      state.displayLayers[layersFirstIndex] = payload[key];
      layersFirstIndex += 1;
    });
  },
  SET_DISPLAY_LAYER(state, payload) {
    if (state.displayLayers) {
      state.displayLayers.push(payload);
    } else {
      state.displayLayers = [payload];
    }
  },
  UPSERT_DISPLAY_LAYER(state, payload) {
    if (state.displayLayers) {
      let found = false;
      Object.keys(state.displayLayers).forEach((key) => {
        if (state.displayLayers[key].id === payload.id) {
          state.displayLayers[key] = payload;
          found = true;
        }
      });
      if (!found) {
        state.displayLayers.push(payload);
      }
    }
  },
  REMOVE_CURRENT_LAYER(state, payload) {
    if (state.currentLayer) {
      state.currentLayer.splice(payload, 1);
    }
  },
};

export const actions = {
  async loadLayers({ commit }, { user } = {}) {
    const toast = useToast();
    const toastId = toast('Loading Layer information');

    const { client_id, id } = user;
    if (client_id) {
      // Retrieves layers being displayed by user
      const layers_display = await axios.get(`/api/json/v1/client/${client_id}/layersdisplay/${id}?limit=500`);
      if (layers_display) {
        await commit('SET_DISPLAY_LAYERS', layers_display.data.layers_display);
      }

      // Retrieves Common Layers
      const common_layers = await axios.get('/api/json/v1/layers/common');
      if (common_layers) {
        await commit('SET_COMMON_LAYERS', common_layers.data.layers);
      }

      // Retrieves client Layers
      const layers = await axios.get(`/api/json/v1/client/${client_id}/layers`);
      if (layers) {
        await commit('SET_LAYERS', layers.data.layers);
      }
      toast.dismiss(toastId);
      return;
    }
    await showError();
  },
  async upsertDisplayLayer({ commit, state }, { payload, user } = {}) {
    showLoading('Applying layers...');
    const { client_id } = user;
    let found = false;
    let layer_display = null;
    if (state.displayLayers) {
      // eslint-disable-next-line
      for (let key = 0; state.displayLayers.length > key; key++) {
        const display = state.displayLayers[key]?.display;
        if (state.displayLayers[key]?.layer_id === payload.id) {
          // eslint-disable-next-line
          layer_display = await axios.post(`/api/json/v1/client/${client_id}/layersdisplay/${user.id}/${payload.id}/0`, { display: !display });
          found = true;
        }
      }
    }

    if (!found) {
      layer_display = await axios.post(`/api/json/v1/client/${client_id}/layersdisplay/${user.id}/${payload.id}/0`, { display: true });
    }

    await commit('UPSERT_DISPLAY_LAYER', layer_display.data.layer_display);
    closeLoading();
    return layer_display;
  },
};
